import React, { FC, useContext } from "react";
import { ReactComponent as Loader } from "../assets/svg/loader.svg";
import { Modal } from "./_ui";
import { WidgetContext } from "../context";
import { LG } from "../constants";
interface IModal {
  openModal: boolean;
  openInfoModal: boolean;
  modalType: string | null;
  setSearchParams: any;
  setOpenModal: (v: boolean) => void;
  setOpenInfoModal: (v: boolean) => void;
  setModalType: (v: "info" | "not found" | null) => void;
  isLoading: boolean;
  orderNumber?: string | null;
  phone?: string | null;
}

const Modals: FC<IModal> = ({
  openModal,
  setOpenModal,
  setModalType,
  modalType,
  setOpenInfoModal,
  openInfoModal,
  isLoading,
  setSearchParams,
  phone,
  orderNumber,
}) => {
  const {
    config: { lg },
  } = useContext(WidgetContext);
  return (
    <>
      {openModal && modalType === "not found" && (
        <Modal
          title={LG[lg].OrderNotFound}
          description={LG[lg].CheckCorrect}
          onClose={() => {
            if (setSearchParams) {
              setSearchParams({ orderNumber, phone, page: "track" });
            }
            setOpenModal(false);
            setModalType(null);
          }}
        />
      )}
      {openInfoModal && modalType === "info" && (
        <Modal
          title=""
          description={LG[lg].UnderDevelop}
          onClose={() => {
            setOpenInfoModal(false);
            setModalType(null);
          }}
        />
      )}
      {isLoading && <Loader />}
    </>
  );
};

export default Modals;
