import styled from "styled-components";

const Order = styled.div`
  padding-top: 45px;
  height: 100%;
  overflow: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    width: 0 !important;
  }
`;

const OrderContent = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 40px;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
  }
`;

const OrderStatus = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 900px) {
    margin-top: 38px;
  }
`;

const OrderDescription = styled.div`
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const OrderTextWrapper = styled.div<{ marginBottom?: string }>`
  display: flex;
  column-gap: 10px;
  margin-bottom: ${({ marginBottom }) => marginBottom || ""};
`;

const CostLowWrapper = styled.div<{ marginBottom?: string }>`
  display: flex;
  justify-content: center;
  column-gap: 10px;
  margin-bottom: ${({ marginBottom }) => marginBottom || ""};
`;

const OrderText = styled.div`
  font-size: 25px;
  line-height: 32px;

  @media screen and (max-width: 1290px) {
    font-size: 20px;
    line-height: 22px;
  }
`;

const OrderTitle = styled.h3`
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  margin: 0;

  @media screen and (max-width: 1290px) {
    font-size: 20px;
    line-height: 22px;
  }
`;
const OrderLink = styled.a<{ linkColor: string }>`
  font-size: 25px;
  line-height: 32px;
  color: ${({ linkColor }) => linkColor};

  @media screen and (max-width: 1290px) {
    font-size: 20px;
    line-height: 22px;
  }
`;
const OrderAddress = styled.p`
  font-size: 25px;
  line-height: 32px;
  max-width: 605px;

  @media screen and (max-width: 1290px) {
    font-size: 20px;
    line-height: 22px;
  }

  @media screen and (max-width: 900px) {
    width: 100%;
  }
`;
const Pay = styled.div`
  margin-top: 40px;
`;
const SendSms = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  margin-top: 51px;
`;
const SendText = styled.h4`
  font-weight: 400;
  font-size: 22px;
  line-height: 29px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackButton = styled.div`
  margin: 20px 0;
  @media screen and (max-width: 900px) {
    text-align: center;
  }
`;

const BackButtonMobile = styled.div`
  margin: 20px 0;
  display: none;
  @media screen and (max-width: 900px) {
    display: block;
    margin-top: 30px;
  }
`;

const BlockPoint = styled.div`
  padding: 38px 0;
`;

const EmptyDivider = styled.div`
  margin-top: 30px;
`;

export const Styled = {
  BackButton,
  BackButtonMobile,
  SendText,
  SendSms,
  Pay,
  OrderAddress,
  OrderLink,
  OrderText,
  OrderTextWrapper,
  OrderTitle,
  Order,
  OrderContent,
  OrderStatus,
  OrderDescription,
  BlockPoint,
  EmptyDivider,
  CostLowWrapper,
};
