import React, { useContext, useEffect } from "react";
import { withLayout } from "./layout";
import { Order } from "./pages";
import { IWidgetContext, WidgetContext } from "./context";
import { IWidgetConfig } from "./interfaces";
import OrderNumberTracking from "./pages/Tracking/_OrderNumberTracking";

const App = () => {
  const { searchParams } = useContext(WidgetContext);
  const { setWidgetConfig, config } = useContext<IWidgetContext>(WidgetContext);
  // @ts-ignore
  const configCustom = window.OmnicConfig as IWidgetConfig;

  useEffect(() => {
    if (setWidgetConfig) {
      setWidgetConfig({ ...config, ...configCustom });
    }
  }, [configCustom]);
  return searchParams?.get("page") === "order" ? (
    <Order />
  ) : (
    <OrderNumberTracking />
  );
};

export default withLayout(App);
