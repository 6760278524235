import styled, {css} from "styled-components";

const StatusItemWrapper = styled.div`
  margin-top: 77px;
`
const StatusItem = styled.div<{border?: boolean, isActive?: boolean; openMore?: boolean;
                   openMoreBlock?: boolean; iconActiveColor: string}>`
  height: ${({openMore, openMoreBlock}) => openMore || openMoreBlock ? "auto" : "128px"};
  width: 250px;
  margin-left: 100px;
  position: relative;
  padding: 20px 0 40px 80px;
  display: flex;
  flex-direction: column;
  transition: .3s;

  ${({border, isActive, iconActiveColor}) => border && css`
      border-left: 2px solid ${isActive ? iconActiveColor : "#7E7E7D"};
  `}
`

const StatusIcon = styled.div<{isActive?: boolean, iconActiveColor: string}>`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: -48px;
  width: 96px;
  height: 94px;
  border-radius: 50%;
  background-color: ${({theme, isActive, iconActiveColor}) => isActive ? iconActiveColor : theme.colors.brown};
`

const Point = styled.div<{isActive?: boolean}>`
  font-weight: 400;
  font-size: 25px;
  line-height: 32px;
  color: ${({isActive}) => isActive ? "#000" : "#7E7E7D"};
`

const More = styled.div<{linkColor: string}>`
  color: ${({linkColor}) => linkColor};
  margin-top: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

const MoreBlock = styled.div<{linkColor: string}>`
  color: ${({linkColor}) => linkColor};
  margin-top: 14px;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  text-decoration-line: underline;
  display: flex;
  align-items: center;
  column-gap: 5px;
  cursor: pointer;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
`

const Date = styled.div`
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  color: #7E7E7D;
`

const Way = styled.div<{openMore: boolean}>`
  margin-left: -85px;
  margin-top: 20px;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7E7E7D;
  display: ${({openMore}) => openMore ? "flex" : "none"};
`

const WayBlock = styled.div<{openMoreBlock: boolean}>`
  margin-left: -85px;
  margin-top: 20px;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  gap: 10px;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #7E7E7D;
  display: ${({openMoreBlock}) => openMoreBlock ? "flex" : "none"};
`

const Circle = styled.div<{iconActiveColor: string}>`
  width: 10px;
  height: 10px;
  background: ${({iconActiveColor}) => iconActiveColor};
  border-radius: 50%;
`

const WayItem = styled.div`
  display: flex;
  width: 330px;
  align-items: center;
  gap: 5px;
`


export const Styled = { WayItem, Circle, Way, WayBlock, Date, More, MoreBlock, Point, StatusItemWrapper, StatusIcon, StatusItem }