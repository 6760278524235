export const RU = {
  delivered: "Доставлен",
  received: "Получен",
  created: "Создан",
  cancel: "Отменён",
  onTheWay: "В пути",
  orderNumber: "Номер заказа",
  shop: "Магазин",
  deliveryDate: "Дата доставки",
  shelfLife: "Срок хранения",
  redirect: "Переадресовать",
  pointOfIssue: "Адрес выдачи",
  amountPayable: "Сумма к оплате",
  pay: "Оплатить",
  back: "Назад",
  deliveryStatus: "Статус доставки",
  SMSWithAReceiptCode: "SMS с кодом получения",
  send: "Отправить",
  track: "Отследить",
  extend: "Продлить",
  more: "Подробнее",
  enterDepartureNumber: "Введите номер отправления",
  enterPhoneNumber: "Введите номер телефона",
  trackDelivery: "ОТСЛЕДИТЬ ДОСТАВКУ",
  orderCard: "Карточка Заказа",
  pointName: "Название точки",
  OrderNotFound: "Заказ не найден",
  CheckCorrect: "Проверьте корректность введённых данных.",
  UnderDevelop: "Упс… данный функционал еще в разработке.",
};

export const EN = {
  delivered: "Ready for Pickup",
  received: "Picked Up",
  created: "Shipment Created",
  cancel: "Cancel",
  onTheWay: "On the way",
  orderNumber: "Shipment number",
  shop: "Sender Name",
  deliveryDate: "Delivery date",
  shelfLife: "Shelf life",
  redirect: "Redirect",
  pointOfIssue: "Destination Locker Address",
  amountPayable: "Amount to be Paid",
  pay: "Pay",
  back: "Back",
  deliveryStatus: "Delivery status",
  SMSWithAReceiptCode: "SMS with a receipt code",
  send: "Send",
  enterPhoneNumber: "Enter phone number",
  enterDepartureNumber: "Enter Tracking Number",
  track: "Track",
  extend: "Extend",
  more: "More",
  trackDelivery: "Shipment Tracking",
  orderCard: "Order Card",
  pointName: "Destination Locker",
  OrderNotFound: "Order not found",
  CheckCorrect: "Check that the data entered is correct.",
  UnderDevelop: "Oops... this functionality is still under development.",
};

export const GE = {
  delivered: "მიწვდომა",
  received: "მიღებული",
  created: "შექმნილია",
  cancel: "Cancel",
  onTheWay: "გზაშია",
  orderNumber: "შეკვეთის ნომერი",
  shop: "მაღაზია",
  deliveryDate: "მიწოდების თარიღი",
  shelfLife: "ვადა",
  redirect: "გადამისამართება",
  pointOfIssue: "მიმართვის ადგილი",
  amountPayable: "გადახდის თანხა",
  pay: "გადახდა",
  back: "უკან",
  deliveryStatus: "მიწოდების სტატუსი",
  SMSWithAReceiptCode: "SMS-ი მიღების კოდით",
  send: "გაგზავნა",
  track: "მიმართვა",
  extend: "გაფრთხილება",
  more: "მეტი",
  enterDepartureNumber: "შეიყვანეთ გადაზიარების ნოერი",
  enterPhoneNumber: "შეიყვანეთ ტელეფონის ნომერი",
  trackDelivery: "მიმართვის მაჩვენებელი",
  orderCard: "შეკვეთის ბარათი",
  pointName: "მიმართვის სახელი",
  OrderNotFound: "შეკვეთა ვერ მოიძებნა",
  CheckCorrect: "გადაამოწმეთ შეყვანილი მონაცემების სისწორე.",
  UnderDevelop: "უუპს... ეს ფუნქცია ჯერ განვითარებული არ არის.",
};

export const SA = {
  delivered: "جاهزة للاستلام",
    received: "استلمت",
    created: "تم انشاء الشحنة",
    cancel: "يلغي",
    onTheWay: "بالطريق للوجهة النهائية",
    orderNumber: "رقم الشحنة",
    shop: "أسم المرسل",
    deliveryDate: "تاريخ التوصيل",
    shelfLife: "مدة التخزين",
    redirect: "تحويل",
    pointOfIssue: "عنوان خزانة الطرود للاستلام",
    amountPayable: "المبلغ المستحق",
    pay: "ادفع",
    back: "الرجوع",
    deliveryStatus: "حالة التوصيل",
    SMSWithAReceiptCode: "رسالة نصية مع رمز التسليم",
    send: "ارسال",
    enterPhoneNumber: "ادخل رقم الجوال",
    enterDepartureNumber: "ادخل رقم الشحنة",
    track: "تتبع",
    extend: "تمديد",
    more: "المزيد",
    trackDelivery: "تتبع الشحنة",
    orderCard: "بيانات الشحنة",
    pointName: "مكان التسليم",
    OrderNotFound: "لم يتم العثور على الشحنة",
    CheckCorrect: "التأكد من صحة البيانات المدخلة.",
    UnderDevelop: "عفوًا... هذه الخاصية لا تزال قيد التطوير.",
};

export const LG = {
  ru: RU,
  en: EN,
  ka: GE,
  ar: SA,
};
