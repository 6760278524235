import { createSlice } from "@reduxjs/toolkit";
import { Tracking } from "./types";

const initialState: Tracking = {
  tracking: "",
  nextPage: false,
};

const trackingSlice = createSlice({
  name: "tracking",
  initialState,
  reducers: {
    addTracking(state, action) {
      state.tracking = action.payload;
    },
    toNextPage(state, action) {
      state.nextPage = action.payload;
    },
  },
});

export const { addTracking, toNextPage } = trackingSlice.actions;

export default trackingSlice.reducer;
