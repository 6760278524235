import React, { useContext, useEffect, useState } from "react";
import { useGetInfoQuery } from "../../store/api/monitoringApi";
import { NumberSender } from "./_NumberSender";
import { Tracking } from "./_Tracking";
import { WidgetContext } from "../../context";
import Modals from "../../components/Modal";
import { useDispatch, useSelector } from "../../store/store";
import { addTracking, toNextPage } from "../../store/tracking/tracking";
import { useLocation, useNavigate } from "react-router-dom";

const OrderNumberTracking = () => {
  const { nextPage, tracking } = useSelector((state) => state.tracking);
  const { search } = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [skip, setSkip] = useState(true);
  const {
    searchParams,
    setSearchParams,
    modalType,
    setModalType,
    config: { lg },
  } = useContext(WidgetContext);

  const [phoneValue, setPhoneValue] = useState(searchParams?.get("phone"));
  const phone = phoneValue?.replace(/[\D]+/g, "");

  {/*}useEffect(() => {
    if (search === "") {
      setSearchParams({});
      navigate("/");
      dispatch(addTracking(""));
      setPhoneValue("");
    }
  }, [search]);*/}

  const { data, isLoading, isError } = useGetInfoQuery(
    {
      q: tracking,
      phone_number: phone,
      INN: 7707419906,
    },
    {
      skip,
    }
  );

  const sendNumberSender = async () => {
    await setPhoneValue("");
    if (setSearchParams && tracking) {
      setSearchParams({ orderNumber: tracking });
      await setSkip(false);
    }
  };

  const sendNumberPhone = () => {
    if (setSearchParams) {
      setSearchParams({ orderNumber: tracking, phone, page: "order" });
    }
    setSkip(false);
  };

  const redirectOrder = async () => {
    if (
      data &&
      data?.message !==
        "More than 1 order found! Only the last one was returned."
    ) {
      await setSkip(true);
      setSearchParams({ orderNumber: tracking, page: "order" });
    }
  };

  useEffect(() => {
    redirectOrder();
    if (
      data &&
      data?.message ===
        "More than 1 order found! Only the last one was returned."
    ) {
      setSkip(true);
      dispatch(toNextPage(true));
    }
  }, [data]);
  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    if (isError) {
      setOpenModal(true);
      setModalType("not found");
    }
  }, [isError]);

  return (
    <>
      {!isLoading && (
        <>
          {!nextPage ? (
            <NumberSender
              sendNumberSender={sendNumberSender}
              orderNumber={tracking}
              setSkip={setSkip}
              lg={lg}
            />
          ) : (
            <Tracking
              sendNumberPhone={sendNumberPhone}
              phoneValue={phoneValue}
              setPhoneValue={setPhoneValue}
              setSkip={setSkip}
              phone={phone}
            />
          )}
        </>
      )}
      <Modals
        modalType={modalType}
        setOpenModal={setOpenModal}
        setModalType={setModalType}
        openInfoModal={openInfoModal}
        orderNumber={tracking}
        openModal={openModal}
        setOpenInfoModal={setOpenInfoModal}
        isLoading={isLoading}
        setSearchParams={setSearchParams}
        phone={phone}
      />
    </>
  );
};

export default React.memo(OrderNumberTracking);
