import { useCallback, useEffect, useState } from "react";

export default function useWatchWidth() {
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const watchForWidthWindow = useCallback(
    () =>
      setTimeout(() => {
        setWindowSize(window.innerWidth);
      }, 50),
    []
  );

  useEffect(() => {
    window.addEventListener("resize", watchForWidthWindow);
    return () => window.removeEventListener("resize", watchForWidthWindow);
  }, [watchForWidthWindow]);

  return windowSize;
}
