import React, {FC, useContext} from 'react';
import { Styled } from './_styles';
import {IWidgetContext, WidgetContext} from "../../../context";

export interface ILinkProps {
        href: string;
        title: string;
}
export const Link:FC<ILinkProps> = ({title, href}) => {
    const {config: {linkColor}} = useContext<IWidgetContext>(WidgetContext)
    return (
    <Styled.Link href={href} linkColor={linkColor}>
        {title}
    </Styled.Link>
)}
