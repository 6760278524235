import React, {
  createContext,
  PropsWithChildren,
  ReactNode,
  useState,
} from "react";
import { useSearchParams } from "react-router-dom";
import { IWidgetConfig } from "../interfaces";
import { theme } from "../styles";

export interface IWidgetContext {
  searchParams?: URLSearchParams;
  setSearchParams?: any;
  config: IWidgetConfig;
  modalType: "info" | "not found" | null;
  setModalType: (type: "info" | "not found" | null) => void;
  setWidgetConfig?: (newConfig: IWidgetConfig) => void;
}

const localize = navigator.language || "en";

const language = localize.split("-")[0];

const lang =
  language !== "ru" && language !== "en" && language !== "ka" && language !== "ar" ? "en" : language;

const DefaultWidgetConfig: IWidgetConfig = {
  lg: lang,
  INN: 7707419906,
  buttonColor: theme.colors.purple,
  buttonFontColor: theme.colors.black,
  linkColor: theme.colors.purple,
  showSendButton: false,
  showPaymentButton: false,
  showExtendButton: false,
  showForwardButton: false,
  iconActiveColor: theme.colors.purple,
};

export const WidgetContext = createContext<IWidgetContext>({
  config: DefaultWidgetConfig,
  modalType: null,
  setModalType: () => {},
});

export const WidgetContextProvider = ({
  children,
}: PropsWithChildren<{ children: ReactNode }>): JSX.Element => {
  const [searchParams, setSearchParams] = useSearchParams({});
  const [config, setConfig] = useState<IWidgetConfig>(DefaultWidgetConfig);
  const [modalType, setModalType] = useState<"info" | "not found" | null>(null);

  const setWidgetConfig = (newConfig: IWidgetConfig): void => {
    setConfig({ ...DefaultWidgetConfig, ...newConfig });
  };
  return (
    <WidgetContext.Provider
      value={{
        modalType,
        setModalType,
        setWidgetConfig,
        config,
        searchParams,
        setSearchParams,
      }}
    >
      {children}
    </WidgetContext.Provider>
  );
};
