import styled, {css} from "styled-components";

const ButtonWrapper = styled.button<{variant?: "primary" | "outline" | "secondary"; buttonColor: string; buttonFontColor: string}>`
    height: 64px;
    border-radius: 20px;
    padding: 0 43px;
    border: none;
    font-weight: 700;
    font-size: 32px;
    line-height: 42px;
    cursor: pointer;
   -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  
  &:disabled {
    background: rgba(0, 0, 0, 0.2);
  }

  @media screen and (max-width: 900px) {
    font-size: 20px;
    line-height: 35px;
    padding: 0 30px;
  }

  @media screen and (max-width: 500px) {
    font-size: 15px;
    line-height: 30px;
    height: 40px;
  }
  
  ${({variant, buttonColor, buttonFontColor}) => variant === "primary" && css`
    background: ${buttonColor};
    color: ${buttonFontColor};
    box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  `}

  ${({variant}) => variant === "outline" && css`
    border: 1px solid black;
    background: none;
  `}

  ${({variant, buttonColor}) => variant === "secondary" && css`
    font-weight: 700;
    font-size: 25px;
    line-height: 32px;
    display: flex;
    align-items: center;
    text-decoration-line: underline;
    padding: 0;
    background: none;
    color: ${buttonColor};
  `}
`

export const Styled = { ButtonWrapper }