import React, { FC, useContext, useState } from "react";
import { Styled } from "./_styles";
import { Button, Input } from "../../components";
import { WidgetContext } from "../../context";
import { LG } from "../../constants";

interface ITracking {
  phoneValue?: string | null;
  phone?: string | null;
  setPhoneValue: (e: string) => void;
  sendNumberPhone: () => void;
  setSkip: (v: boolean) => void;
}

export const Tracking: FC<ITracking> = ({
  phoneValue,
  setPhoneValue,
  sendNumberPhone,
  phone,
  setSkip,
}) => {
  const {
    config: { lg },
  } = useContext(WidgetContext);

  const [isPhoneValueEmpty, setIsPhoneValueEmpty] = useState(false);
  const [disabled, setIsDisabled] = useState(!isPhoneValueEmpty);

  return (
    <Styled.Tracking>
      <Styled.TrackingInputWrapper>
        <Input
          onBlur={() =>
            !!phone?.length && phone.length < 12
              ? setIsPhoneValueEmpty(true)
              : setIsPhoneValueEmpty(false)
          }
          showError={isPhoneValueEmpty}
          onChange={(e) => {
            if (e.target.value.replace(/[\D]+/g, "").length === 12 /*||
                e.target.value.replace(/[\D]+/g, "").length === 11*/) {
              setIsPhoneValueEmpty(false);
              setIsDisabled(false);
            } else {
              setIsDisabled(true);
            }
            setSkip(true);

            setPhoneValue(e.target.value);
          }}
          value={phoneValue || ""}
          mask="+999 99-999-9999"
          title={LG[lg].enterPhoneNumber}
        />
      </Styled.TrackingInputWrapper>
      <Button
        disabled={disabled}
        title={LG[lg].track}
        onClick={sendNumberPhone}
      />
    </Styled.Tracking>
  );
};
