import React, {FC, ReactNode} from 'react';
import { Styled } from './_styles';
import {Button, Link} from "../_ui";

export interface IOrderCardProps {
    children: ReactNode;
    onClick?: () => void;
    linkName?: string;
    paddingBottom?: string;
    showLink: boolean;
}

export const OrderCard:FC<IOrderCardProps> = ({showLink, children, paddingBottom, onClick, linkName}) => (
        <Styled.OrderCardWrapper paddingBottom={paddingBottom}>
            {children}
            {showLink && onClick && linkName && <Button variant="secondary" onClick={onClick} title={linkName}/>}
        </Styled.OrderCardWrapper>
);

OrderCard.defaultProps = {
    showLink: false
}