import styled, { css } from "styled-components";

const MainWrapper = styled.div<{ isOpenModal?: boolean }>`

  width: 100%;
  ${({ isOpenModal }) =>
    isOpenModal &&
    css`
      overflow: hidden;
    `}
`;

const MainContainer = styled.div`
  padding: 0;

  position: relative;

  @media screen and (max-width: 900px) {
    padding: 40px;
  }

  @media screen and (max-width: 320px) {
    padding: 40px 10px 40px;
  }
`;

const Main = styled.main``;

export const Styled = { MainWrapper, MainContainer, Main };
