import React, { FC, useState } from "react";
import { Styled } from "./_styles";
import { Button, Input } from "../../components";
import { LG } from "../../constants";
import { useDispatch } from "../../store/store";
import { addTracking } from "../../store/tracking/tracking";

interface INumberSender {
  sendNumberSender: () => void;
  orderNumber?: string | null;
  lg: "en" | "ru" | "ka" | "ar";
  setSkip: (v: boolean) => void;
}

export const NumberSender: FC<INumberSender> = ({
  sendNumberSender,
  orderNumber,
  lg,
  setSkip,
}) => {
  const dispatch = useDispatch();
  const [isOrderNumberEmpty, setIsOrderNumberEmpty] = useState(false);
  const [disabled, setIsDisabled] = useState(!orderNumber?.length);
  const handleSetParamsClick = () => {
    sendNumberSender();
  };

  return (
    <Styled.Tracking>
      <Styled.TrackingInputWrapper>
        <Input
          showError={isOrderNumberEmpty}
          onChange={(e) => {
            if (e.target.value.length >= 1) {
              setIsOrderNumberEmpty(false);
              setIsDisabled(false);
            } else {
              setIsDisabled(true);
            }
            setSkip(true);
            dispatch(addTracking(e.target.value));
          }}
          onBlur={() =>
            orderNumber && orderNumber.length < 1
              ? setIsOrderNumberEmpty(true)
              : setIsOrderNumberEmpty(false)
          }
          value={orderNumber || ""}
          title={LG[lg].enterDepartureNumber}
          maxLength={15}
        />
      </Styled.TrackingInputWrapper>
      <Button
        disabled={disabled}
        title={LG[lg].track}
        onClick={handleSetParamsClick}
      />
    </Styled.Tracking>
  );
};
