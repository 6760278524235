import React, {FC} from 'react';
import { Styled } from './_styles';
import {Button} from "../Button";

export interface IModalProps {
    title: string;
    description: string;
    onClose: () => void;
}

export const Modal:FC<IModalProps> = ({title, description, onClose}) => (
        <Styled.ModalWrapper onClick={onClose}>
            <Styled.ModalContent>
                <Styled.ModalInfo>
                    <Styled.ModalTitle>{title}</Styled.ModalTitle>
                    <Styled.ModalDescription>{description}</Styled.ModalDescription>
                </Styled.ModalInfo>
                <Button title="OK" onClick={onClose}/>
            </Styled.ModalContent>
        </Styled.ModalWrapper>
    );