import React, { FC, useContext } from "react";
import { Styled } from "./_styles";
import { ReactInputMask } from "./_input.props";
import { WidgetContext } from "../../../context";
import { LG } from "../../../constants";

export const Input: FC<ReactInputMask> = ({
  onBlur,
  onClick,
  maxLength,
  title,
  mask,
  showError,
  value,
  onChange,
}) => {
  const {
    config: { lg },
  } = useContext(WidgetContext);
  return (
    <Styled.InputWrapper onClick={onClick}>
      <Styled.InputTitle>{title}</Styled.InputTitle>
      {mask ? (
        <Styled.InputWithMask
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          mask={mask || ""}
        />
      ) : (
        <Styled.Input
          onBlur={onBlur}
          onChange={onChange}
          value={value}
          maxLength={maxLength}
        />
      )}
      {showError && (
        <Styled.InputError>{LG[lg].CheckCorrect}</Styled.InputError>
      )}
    </Styled.InputWrapper>
  );
};

Input.defaultProps = {
  showError: false,
};
