import styled from "styled-components";

const Tracking = styled.div`
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 90px;
    padding-top: 100px;

  @media screen and (max-width: 900px) {
    row-gap: 60px;
    padding-top: 20px;
  }

  @media screen and (max-width: 500px) {
    row-gap: 30px;
    padding-top: 20px;
  }
`

const TrackingInputWrapper = styled.div`
    display: flex;
    justify-content: center;
    gap: 89px;
    margin-bottom: 72px;

  @media screen and (max-width: 900px) {
    flex-wrap: wrap;
    margin-bottom: 20px;
    gap: 60px;
  }

  @media screen and (max-width: 500px) {
    gap: 40px;
  }
`

export const Styled = { Tracking, TrackingInputWrapper }