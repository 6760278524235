import { ITheme } from "./_interfaces";

export const theme: ITheme = {
  colors: {
    black: "#000",
    white: "#FFF",
    green: "#BBE849",
    brown: "#D9D9D9",
    purple: "#A692C4",
    stubBrown: "rgba(240, 240, 243, 0.55)",
  },
  breakpoints: {
    mobile: "834px",
    desktop: "1200px",
  },
};
