import React, {FC, useContext} from 'react';
import { Styled } from './_styles';
import {IWidgetContext, WidgetContext} from "../../../context";

export interface IButtonProps {
        title: string;
        variant?: "primary" | "secondary" |"outline";
        disabled?: boolean;
        onClick: () => void
}

export const Button:FC<IButtonProps> = ({variant, disabled, title, onClick}) => {
        const {config: {buttonColor, buttonFontColor}} = useContext<IWidgetContext>(WidgetContext)
        return (
        <Styled.ButtonWrapper buttonColor={buttonColor} buttonFontColor={buttonFontColor} variant={variant} disabled={disabled} onClick={onClick}>
                {title}
        </Styled.ButtonWrapper>
)}

Button.defaultProps = {
        variant: "primary"
}