import styled from "styled-components";

const Link = styled.a<{linkColor: string}>`
  font-weight: 700;
  font-size: 25px;
  line-height: 32px;
  display: flex;
  align-items: center;
  text-decoration-line: underline;
  color: ${({linkColor}) => linkColor};
`

export const Styled = { Link }