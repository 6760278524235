import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { IMonitoringData } from "../../interfaces";

export const monitoringApi = createApi({
  reducerPath: "monitoringApi",
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PUBLIC_URL,
    prepareHeaders: (headers) => {
      headers.set("Content-Type", "application/json");
      return headers;
    },
  }),
  endpoints: (builder) => ({
    getInfo: builder.query<
      IMonitoringData,
      { q?: string | null; phone_number?: string | null; INN: number }
    >({
      query: ({ q, phone_number, INN }) => ({
        url: phone_number
          ? `info?q=${q}&phone_number=${phone_number}`
          : `info?q=${q}`,
        headers: { Authorization: `INN ${INN}` },
      }),
    }),
  }),
});

export const { useGetInfoQuery } = monitoringApi;
