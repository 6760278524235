import styled from "styled-components";

const OrderCardWrapper = styled.div<{ paddingBottom?: string }>`
  display: flex;
  flex-direction: column;
  max-width: 680px;
  border-bottom: 1px solid #7e7d7d;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  @media screen and (max-width: 900px) {
    width: 100%;
    align-items: center;
  }
`;

export const Styled = { OrderCardWrapper };
