import {FC, FunctionComponent, ReactNode, useContext} from "react";
import {Styled} from "./_styles";
import {Header} from "./Header/_Header";
import {IWidgetContext, WidgetContext} from "../context";

export const _Layout: FC<{children: ReactNode}> = ({ children, ...props }) => {
    const {modalType} = useContext<IWidgetContext>(WidgetContext)
    return (
    <Styled.MainWrapper isOpenModal={!!modalType}>
        <Styled.MainContainer {...props}>
            <Header/>
            <Styled.Main>
                {children}
            </Styled.Main>
        </Styled.MainContainer>
    </Styled.MainWrapper>
)};

export const withLayout =
    <T extends Record<string, unknown>>(Component: FunctionComponent<T>) =>
        (props: T) =>
            (
                <_Layout>
                    <Component {...props} />
                </_Layout>
            );