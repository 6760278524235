import React, { useContext, useEffect, useState } from "react";
import { Styled } from "./_styles";
import { useGetInfoQuery } from "../../store/api/monitoringApi";
import { useNavigate } from "react-router-dom";
import { Button, Link, OrderCard, StatusItem } from "../../components";
import { WidgetContext } from "../../context";
import { LG } from "../../constants";
import Modals from "../../components/Modal";
import { addTracking, toNextPage } from "../../store/tracking/tracking";
import { useDispatch } from "../../store/store";
import useWatchWidth from "../../hooks/useWatchWidth";
import { IMonitoringData } from "../../interfaces/_monitoring.interfaces";

export const Order = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const width = useWatchWidth();

  const {
    modalType,
    setModalType,
    setSearchParams,
    searchParams,
    config: {
      showExtendButton,
      showForwardButton,
      showSendButton,
      linkColor,
      showPaymentButton,
      lg,
      INN,
    },
  } = useContext(WidgetContext);

  const orderNumber = searchParams?.get("orderNumber");
  const phone = searchParams?.get("phone");
  const { data, isLoading, isError } = useGetInfoQuery({
    q: orderNumber,
    phone_number: phone,
    INN,
  });

  const [openModal, setOpenModal] = useState(false);
  const [openInfoModal, setOpenInfoModal] = useState(false);

  useEffect(() => {
    if (isError) {
      setOpenModal(true);
      setModalType("not found");
    }
  }, [isError]);

  const handleSetOpenInfoModal = () => {
    setOpenInfoModal(true);
    setModalType("info");
  };

  const clearAndBack = () => {
    setSearchParams({});
    dispatch(toNextPage(false));
    dispatch(addTracking(""));
    //navigate("/");
  };

/*
  const lat = data?.data.point_latitude_to;
  const long = data?.data.point_longitude_to;
console.log(long);
  */
/*  const generateGoogleMapsURL = (lat, long)  => {*//*

    const latStr = "52%C2%B002'47.2"//data?.data.point_latitude_to.toString().replace('.', '%C2%B0').replace('.', "'");
    const longStr = "4%C2%B027'93.22"//data?.data.point_longitude_to.toString().replace('.', '%C2%B0').replace('.', "'");
    const url = `https://www.google.com/maps/place/${latStr}"N+${longStr}"E/@${lat},${long},14z?entry=ttu`;

    //return url;

    console.log(longStr);
 */

  return (
    <Styled.Order>
      <Modals
        modalType={modalType}
        setOpenModal={setOpenModal}
        setModalType={setModalType}
        openInfoModal={openInfoModal}
        orderNumber={orderNumber}
        openModal={openModal}
        setOpenInfoModal={setOpenInfoModal}
        isLoading={isLoading}
        setSearchParams={setSearchParams}
        phone={phone}
      />
      {!isLoading && !isError && (
        <Styled.OrderContent>
          <Styled.OrderDescription>
            <OrderCard
              showLink={showExtendButton}
              linkName={LG[lg].extend}
              onClick={handleSetOpenInfoModal}
              paddingBottom={"38px"}
            >
              <Styled.OrderTextWrapper>
                <Styled.OrderTitle>{LG[lg].orderNumber}:</Styled.OrderTitle>
                <Styled.OrderText>{data?.data.vendor_number}</Styled.OrderText>
              </Styled.OrderTextWrapper>
              <Styled.OrderTextWrapper>
                <Styled.OrderTitle>{LG[lg].shop}:</Styled.OrderTitle>
                <Styled.OrderText>{data?.data.vendor_name}</Styled.OrderText>
              </Styled.OrderTextWrapper>
            </OrderCard>

            <OrderCard
              showLink={showForwardButton}
              linkName={LG[lg].redirect}
              onClick={handleSetOpenInfoModal}
            >
              <Styled.BlockPoint>
                {!data?.data.point_code_to.includes("ADDR") && (
                  <Styled.OrderTextWrapper>
                    <Styled.OrderTitle style={{ marginRight: "15px" }}>
                      {LG[lg].pointName}:
                    </Styled.OrderTitle>
                    <Styled.OrderTextWrapper>
                    <Styled.OrderTitle style={{ marginRight: "15px" }}>
                      {data?.data.point_name_to || data?.data.point_code_to}
                      </Styled.OrderTitle>
                    </Styled.OrderTextWrapper>
                  </Styled.OrderTextWrapper>
                )}
                <Styled.OrderTextWrapper>
                  <Styled.OrderTitle>{LG[lg].pointOfIssue}:</Styled.OrderTitle>
                  <Styled.OrderAddress>
                    {data?.data.address_to}
                  </Styled.OrderAddress>
                </Styled.OrderTextWrapper>
              </Styled.BlockPoint>
            </OrderCard>
            {width > 900 && (
              <Styled.BackButton>
                {!!data?.data.total_receiver_payment && (
                  <Styled.Pay>
                    <Styled.OrderTextWrapper marginBottom="44px">
                      <Styled.OrderTitle>
                        {LG[lg].amountPayable}:
                      </Styled.OrderTitle>
                      <Styled.OrderText>
                        {data?.data.total_receiver_payment} SAR
                      </Styled.OrderText>
                    </Styled.OrderTextWrapper>
                    {showPaymentButton && (
                      <Button title={LG[lg].pay} onClick={() => alert("")} />
                    )}
                  </Styled.Pay>
                )}
                <Button
                  variant="outline"
                  title={LG[lg].back}
                  onClick={clearAndBack}
                />
              </Styled.BackButton>
            )}
          </Styled.OrderDescription>
          <Styled.OrderStatus>
            <Styled.OrderTitle>{LG[lg].deliveryStatus}</Styled.OrderTitle>
            <StatusItem logs={data?.data.logs} />
            {showSendButton && (
              <Styled.SendSms>
                <Styled.SendText>
                  {LG[lg].SMSWithAReceiptCode}
                  <Link href="#" title={LG[lg].send} />
                </Styled.SendText>
              </Styled.SendSms>
            )}
          </Styled.OrderStatus>
        </Styled.OrderContent>
      )}
      {width <= 900 && (
        <Styled.BackButton>
          {!!data?.data.cod_value && (
            <Styled.Pay>
              <Styled.CostLowWrapper marginBottom="22px">
                <Styled.OrderTitle>{LG[lg].amountPayable}:</Styled.OrderTitle>
                <Styled.OrderText>{data?.data.cod_value} SAR</Styled.OrderText>
              </Styled.CostLowWrapper>
              {showPaymentButton && (
                <Button title={LG[lg].pay} onClick={() => alert("")} />
              )}
            </Styled.Pay>
          )}
          <Button
            variant="outline"
            title={LG[lg].back}
            onClick={clearAndBack}
          />
        </Styled.BackButton>
      )}
    </Styled.Order>
  );
};
