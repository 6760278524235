import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  TypedUseSelectorHook,
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
} from "react-redux";
import {
  FLUSH,
  PAUSE,
  PERSIST,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from "redux-persist";

import { monitoringApi } from "./api/monitoringApi";
import tracking from "./tracking/tracking";
import storageSession from "redux-persist/lib/storage/session";

const reducers = combineReducers({
  tracking: persistReducer(
    { key: "tracking", storage: storageSession },
    tracking
  ),
  [monitoringApi.reducerPath]: monitoringApi.reducer,
});

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) => {
    return getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE],
      },
    }).concat(monitoringApi.middleware);
  },
});
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof reducers>;

export type AppDispatch = typeof store.dispatch;

export const useDispatch = () => useAppDispatch<AppDispatch>();
export const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;
