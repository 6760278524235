import styled from "styled-components";
import InputMask from 'react-input-mask';

const  InputWrapper = styled.div`
  position: relative;
  max-width: 31.9375rem;
  height: 5rem;

  @media screen and (max-width: 900px) {
    width: 27rem;
    height: 4rem;
  }

  @media screen and (max-width: 500px) {
    width: 100%;
    height: 3rem;
  }
`

const InputTitle = styled.div`
  font-weight: 400;
  font-size: 2rem;
  line-height: 42px;
  margin: 0.5rem 2rem;
  white-space: nowrap;

  @media screen and (max-width: 1200px) {
    font-size: 1.5rem;
    line-height: 21px;
    margin-bottom: 0.5rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 0.7rem;
    line-height: 15px;
  }
`

const InputError = styled.div`
  font-style: normal;
  font-weight: 400;
  font-size: 18px;
  line-height: 23px;
  position: absolute;
  margin-top: 19px;
  color: #F80505;

  @media screen and (max-width: 500px) {
    font-size: 10px;
    line-height: 20px;
    margin-top: 5px;
  }
`


const Input = styled.input`
  width: 100%;
  height: 5rem;
  background: #D9D9D9;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 2rem;
  line-height: 42px;
  color: #7E7D7D;
  padding: 8px 28px;

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    line-height: 21px;
    height: 3rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1rem;
    line-height: 15px;
    height: 2rem;
  }
`

const InputWithMask = styled(InputMask)`
  width: 100%;
  height: 5rem;
  background: #D9D9D9;
  box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 20px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 2rem;
  line-height: 42px;
  color: #7E7D7D;
  padding: 8px 28px;

  @media screen and (max-width: 900px) {
    font-size: 1.5rem;
    line-height: 21px;
    height: 3rem;
  }

  @media screen and (max-width: 500px) {
    font-size: 1rem;
    line-height: 15px;
    height: 2rem;
  }
`

export const Styled = { InputWithMask, InputWrapper, InputError,InputTitle ,Input }